<template>
  <BaseModal v-model="modelValue">
    <template #header>{{ t('common.switchLanguageCurrency') }}</template>

    <template #default>
      <div>
        <div class="pb-10 space-y-6">
          <MosaicUiDropdownsDefaultDropdown :label="t('common.selectLanguage')"
                                            v-model="currentLocale"
                                            :options="availableLocales"
          />

          <MosaicUiDropdownsDefaultDropdown :label="t('common.selectCurrency')"
                                            v-model="currency"
                                            :options="[
                                              {label: t('common.currencies.UAH'), value: 'UAH'},
                                              {label: t('common.currencies.USD'), value: 'USD'},
                                              {label: t('common.currencies.EUR'), value: 'EUR'},
                                              {label: t('common.currencies.GBP'), value: 'GBP'},
                                              {label: t('common.currencies.CZK'), value: 'CZK'},
                                            ]"
          />
        </div>

        <MosaicUiButtonsPrimaryButton class="w-full" @click="modelValue = false">{{ t('common.save') }}</MosaicUiButtonsPrimaryButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from "./BaseModal.vue";

const {t, locale, locales, setLocale} = useI18n();
const switchLocalePath = useSwitchLocalePath();
const {currency} = useCurrency(locale.value);

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  }
})
const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value: any) {
    emit('update:modelValue', value);
  }
});

const availableLocales = computed(() => {
  const list = [];
  locales.value.forEach(item => {
    list.push({
      value: item.code,
      label: item.name,
    });
  });
  return list;
});

const currentLocale = computed({
  get() {
    return locale.value;
  },
  async set(localeCode) {
    await navigateTo(switchLocalePath(localeCode));
  }
});
</script>

<style scoped>

</style>
