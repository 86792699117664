<template>
    <footer>
        <div class="container">
            <div class="columns is-justify-content-space-between">
                <div class="column" style="flex: 1">
                    <div class="logo">
                        <NuxtLink :to="localePath('/')">
                            <CoreLogo />
                        </NuxtLink>
                    </div>
                    <div class="text" v-if="isHomePage">
                        {{ t(`meclee-ui.base.footer.homepage`) }}
                    </div>
                </div>
                <div class="column">
                    <div class="additional-info">
                        <p class="has-text-weight-bold">{{ t('meclee-ui.base.footer.center.header') }}</p>
                        <div class="notice">
                          <i18n-t keypath="meclee-ui.base.footer.center.emergency.text" tag="p" style="max-width: 300px">
                            <template v-slot:link>
                              <NuxtLink class="accent" :to="localePath('/emergency')">
                                {{ t('meclee-ui.base.footer.center.emergency.link') }}
                              </NuxtLink>
                            </template>
                          </i18n-t>
                            <p style="max-width: 300px;margin-top: 20px;">
                                <NuxtLink class="accent" :to="localePath('/russkiykorabl-idinahui')">
                                  {{ t('meclee-ui.base.footer.center.russkiykorabl') }}
                                </NuxtLink>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="additional-info">
                        <p class="has-text-weight-bold">{{ t('meclee-ui.menu.footer.header') }}</p>
                        <nav>
                            <NuxtLink :to="localePath('/about-us')">{{ t('meclee-ui.menu.footer.aboutUs') }}</NuxtLink>
                            <NuxtLink :to="localePath('/pages/privacy')">{{ t('meclee-ui.menu.footer.privacy') }}</NuxtLink>
                            <NuxtLink :to="localePath('/contacts')">{{ t('meclee-ui.menu.footer.contacts') }}</NuxtLink>
                            <NuxtLink :to="localePath('/pages/agreement')">{{ t('meclee-ui.menu.footer.agreement') }}</NuxtLink>
<!--                            <NuxtLink :to="localePath('/check-up')">{{ t('meclee-ui.menu.footer.compatibility') }}</NuxtLink>-->
                            <NuxtLink :to="localePath('/kids')">{{ t('meclee-ui.menu.footer.kids') }}</NuxtLink>
                            <NuxtLink :to="localePath('/family')">{{ t('meclee-ui.menu.footer.family') }}</NuxtLink>
                            <NuxtLink :to="localePath('/business')">{{ t('meclee-ui.menu.footer.business') }}</NuxtLink>
                        </nav>
                    </div>
                </div>
                <div class="column">
                    <div class="additional-info">
                        <p class="has-text-weight-bold">{{ t('meclee-ui.base.footer.right.header') }}</p>
                        <nav>
                          <p class="font-medium mb-3">{{ $t('meclee-ui.footer.forCustomers') }}</p>
                          <div>
                            <a href="mailto:support@meclee.com" class="mail">support@meclee.com</a>
                            <a href="https://www.instagram.com/meclee_com/" target="_blank" class="instagram"
                                style="display: flex">
                                <InstagramIcon /> meclee_com
                            </a>
                            <a href="https://t.me/MecleeBot" target="_blank" class="telegram" style="display: flex">
                                <TelegramIcon /> @MecleeBot
                            </a>
                          </div>

                          <p class="font-medium my-3">{{ $t('meclee-ui.footer.forPsychologists') }}</p>
                          <div>
                            <a href="https://t.me/MecleeBot" target="_blank" class="telegram" style="display: flex">
                              <TelegramIcon /> @MecleeBot
                            </a>
                          </div>

                          <p class="font-medium my-3">{{ $t('meclee-ui.footer.forBusiness') }}</p>
                          <div>
                            <a href="mailto:b2b@meclee.com" class="mail">b2b@meclee.com</a>
                          </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup>
import InstagramIcon from "../assets/icons/instagram_icon.svg"
import TelegramIcon from "../assets/icons/telegram_icon.svg"
const { t } = useI18n();
const route = useRoute()
import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const isHomePage = computed(() => {
    return route.path === '/'
})
</script>
