<template>
  <div class="p-[17px_20px] relative flex items-center logged-dropdown pr-5 lg:pr-0" ref="element">
    <div class="cursor-pointer text-meclee-blue-500"
         v-if="isLoggedIn"
         @click="isOpened = !isOpened"
    >
      <span class="icon-user mr-3"></span>
      <span>{{ profileLabel }}</span>
    </div>
    <NuxtLink v-else :to="localePath($config.public.auth.signInRoute)">
      <span class="icon-user mr-3"></span>
      <span>{{ t('meclee-ui.menu.login') }}</span>
    </NuxtLink>

    <div class="absolute top-10 right-5 lg:right-0 shadow-xl p-4 rounded-xl w-48 border z-30 bg-white mt-2" v-show="isOpened">
      <ul class="space-y-2">
        <li>
          <NuxtLink class="w-full text-sm text-meclee-blue-600 block w-full" :to="localePath(cabinetHomePagePath)">
            {{ t('meclee-ui.auth.cabinet') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink class="w-full text-sm text-meclee-blue-600 block w-full" @click="logout(false); isOpened = false">
            {{ t('meclee-ui.auth.logout') }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
const localePath = useLocalePath();
const { t, locale } = useI18n();
const config = useRuntimeConfig();
const {session} = useAuthSession();
const { showLoader, hideLoader } = useLoader();
const { isLoading, logout } = useLogin();

const isLoggedIn = computed(() => session.value?.selectedProfile !== undefined);
const profileLabel = computed(() => session.value?.selectedProfile.label);
const cabinetHomePagePath = computed(() => {
  return config.public.auth.homePagesByProfile[session.value?.selectedProfile.type];
});

watchEffect(() => {
  if (isLoading.value) {
    showLoader();
  } else {
    hideLoader();
  }
});

const element = ref();
const isOpened = ref(false);
onClickOutside(element, (event) => {
  isOpened.value = false;
});
</script>

<style scoped>
.logged-dropdown > *:hover {
  @apply text-meclee-black-400;
}
</style>
